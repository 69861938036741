<template>
  <div class="requisites-page container">
    <h1>Реквизиты</h1>
    <div class="requisites-page__content">
      <span>
        <b>ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ</b>
        ДЖАБРАИЛОВА ГУЛЬПИЯЗ ШИХМУРАДОВНА

        <b>Юридический адрес организации</b>
        368590, РОССИЯ, РЕСП ДАГЕСТАН, КАЙТАГСКИЙ Р-Н, СЕЛО МАДЖАЛИС, УЛ АЛИСУЛТАНОВА, Д 32

        <b>ИНН</b>
        051400529392

        <b>ОГРН</b>
        321057100080135

        <b>Расчетный счет </b>
        40802810900004091419

        <b>Банк</b>
        АО "ТИНЬКОФФ БАНК"

        <b>ИНН банка </b>
        7710140679

        <b>БИК банка </b>
        044525974

        <b>Корреспондентский счет банка </b>
        30101810145250000974

        <b>Юридический адрес банка </b>
        Москва, 127287, ул. Хуторская

        <b>Юридический адрес банка </b>
        Москва, 127287, ул. Хуторская 2-я, д. 38А, стр. 26
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequisitesPage",
};
</script>

<style lang="stylus">
.requisites-page {
  padding 140px 0 80px 0
  display flex
  flex-direction column
  gap 30px
  +below(1330px) {
    padding 30px 15px
    padding-top: 140px;
    gap 20px
  }

  &__content {
    & span {
      white-space pre-line
    }
  }
}
</style>
